import { required as vRequired, email as vEmail, minLength as vMinLength, maxLength as vMaxLength, sameAs as vSameAs, helpers } from '@vuelidate/validators'

export const email = helpers.withMessage("E-mail inválido", vEmail);

export const required = helpers.withMessage("Campo obrigatório", vRequired);

export const sameAs = <E = unknown>(equalTo: E, otherName: string) => {
  return helpers.withMessage(`Deve ser igual a ${otherName}`, vSameAs(equalTo));
}

export const minLength = (min: number | Ref<number>) =>
  helpers.withMessage(`Deve conter no mínimo ${min} caracteres`, vMinLength(min));

export const maxLength = (max: number | Ref<number>) =>
  helpers.withMessage(`Deve conter no máximo ${max} caracteres`, vMaxLength(max));


export const date =
  helpers.withMessage('Deve ser uma data válida', (text: string): boolean => {
    const digits = text.replace(/[^0-9]/g, "");

    return digits.length == 8;
  });

export const validPhoneNumber =
  helpers.withMessage('Deve ser um telefone válido', (text: string): boolean => {
    const digits = text.replace(/[^0-9]/g, "");

    return digits.length >= 10 && digits.length <= 11;
  });


export const validCPF =
  helpers.withMessage('Deve ser um CPF válido', (text: string): boolean => {
    const cpf = text.replace(/[\s.-]*/igm, '');

    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false;
    }

    var sum = 0;
    var remainder: number;

    for (var i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i)
    }

    remainder = (sum * 10) % 11;

    if ((remainder == 10) || (remainder == 11)) {
      remainder = 0
    }

    if (remainder != parseInt(cpf.substring(9, 10)) ) return false

    sum = 0;

    for (var i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i)
    }

    remainder = (sum * 10) % 11

    if ((remainder == 10) || (remainder == 11)) {
      remainder = 0;
    }

    if (remainder != parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  });